#db {
    background: url('../../../public/images/bg-slider.svg') no-repeat center;
    background-size: cover;
    padding: 8% 0 15% 0;
    color: #fff;
}

@media screen and (max-width: 767px) {
    #db {
        padding-bottom: 65% !important;
    }
}

@media screen and (max-width: 990px) {
    #db {
        padding-bottom: 30%;
    }
}