#detail {
    background: url('../../../public/images/bg-slider.svg') center no-repeat;
    background-size: cover;
    padding: 5% 0 10% 0;
    color: #fff;
}

.open {
    display: block;
}

.tab-menu {
    display: flex;
    column-gap: 80px;
    justify-content: center;
    position: relative;
    z-index: 3;
}

.menu {
    padding: 16px;
    cursor: pointer;
    white-space: nowrap;
    transition: .3s ease-in;
    border-bottom: 2px solid transparent;
    -webkit-transition: .3s ease-in;
    -moz-transition: .3s ease-in;
    -ms-transition: .3s ease-in;
    -o-transition: .3s ease-in;
}

.menu.active {
    border-bottom: 2px solid #fff;
}

.btn-arrow {
    border: none;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 2em;
    display: inline-flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    z-index: 2;
    justify-content: center;
    background: var(--purple);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.btn-arrow i {
    cursor: pointer;
}

.btn-arrow:disabled {
    opacity: .5;
}

.btn-next {
    right: 0;
    top: 50%;
}

.btn-prev {
    left: 0;
    top: 50%;
}

@media screen and (max-width: 767px) {
    #detail {
        padding-bottom: 65% !important;
    }
    .tab-menu {
        /* flex-wrap: wrap; */
        justify-content: unset;
        overflow: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    .tab-menu::-webkit-scrollbar {
        display: none;
    }
    .menu {
        width: 0;
        display: none;
    }
    .menu.active {
        width: fit-content;
        display: block;
        margin: 0 auto;
    }
}

@media screen and (max-width: 990px) {
    #detail {
        padding-bottom: 30%;
    }
    .tab-menu {
        column-gap: 40px;
    }
    .btn-next {
        right: 0;
    }
    .btn-prev {
        left: 0;
    }
    .btn-prev, .btn-next {
        top: 40%;
    }
}

@media screen and (max-width: 1280px) {
    .project-img {
        max-width: 80%;
    }
}

@media screen and (min-width: 1400px) {
    .btn-next {
        right: -10%;
    }

    .btn-prev {
        left: -10%;
    }
}