.faq {
    margin-bottom: 20%;
}

.accordion-item {
    border-bottom: 1px solid #EDEDED;
    padding: 20px 28px;
    text-align: left;
    cursor: pointer;
    transition: .3s ease;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -ms-transition: .3s ease;
    -o-transition: .3s ease;
}

.accordion-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    cursor: pointer;
    /* margin-bottom: 20px; */
    transition: .3s ease;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -ms-transition: .3s ease;
    -o-transition: .3s ease;
}

.accordion-content {
    height: 0;
    transition: all .3s;
    transform-origin: top;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
}

.accordion-item.active {
    background-color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-bottom: 1px solid transparent;
}

.accordion-item.active .accordion-content {
    height: auto;
    transform: translateY(5px);
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    -o-transform: translateY(5px);
}

@media screen and (max-width: 767px) {
    .faq-wrap {
        margin-bottom: 65% !important;
    }
}

@media screen and (max-width: 990px) {
    .faq-wrap {
        margin-bottom: 30%;
    }
}