.tab {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 100px;
    column-gap: 20px;
    row-gap: 40px;
}

.tab-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #000;
    background-color: #fff;
    padding: 30px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    position: relative;
}

.tab-card:not(.r-tag) {
    text-align: left;
}

.recommend {
    /* background-color: var(--purple);
    color: #fff; */
    /* padding: 30px 50px; */
}

.r-tag {
    background-color: #10B981;
    position: absolute;
    inset: -5% 0 auto 0;
    width: fit-content;
    /* top: -5%; */
    /* left: 70px; */
    margin-inline: auto;
    padding: 8px 15px;
    font-weight: 500;
    letter-spacing: .12em;
    font-size: .75em;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.price {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(31, 31, 31, 0.2);
}

.price-text {
    font-size: 3.0635em;
    line-height: 74px;
    font-weight: 600;
}

.card-list {
    margin: 20px 0;
}

.card-list li {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 10px;
}

/* .tab-card:not(.recommend) li svg {
    color: var(--purple);
} */

.tab-card li svg {
    color: var(--purple);
}

.btn-container {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
    .tab {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 900px) {
    .tab {
        grid-template-columns: repeat(2, 1fr);
    }
}
