nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 15px 0;
    z-index: 998;
    background: transparent;
    transition: .3s ease-in;
    -webkit-transition: .3s ease-in;
    -moz-transition: .3s ease-in;
    -ms-transition: .3s ease-in;
    -o-transition: .3s ease-in;
}

.brand {
    display: flex;
    align-items: center;
    font-size: 2.25em;
    font-weight: 800;
    color: #fff;
    column-gap: 10px;
}

.navbar-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-left,
.navbar-right {
    display: flex;
    align-items: center;
}

.navbar-left {
    column-gap: 50px;
    -moz-column-gap: 50px;
    -webkit-column-gap: 50px;
}

.navbar-right {
    column-gap: 20px;
    -moz-column-gap: 20px;
    -webkit-column-gap: 20px;
}

.navbar-right .btn {
    padding: 12px 32px;
}

.nav-menu {
    display: flex;
    column-gap: 35px;
    -moz-column-gap: 35px;
    -webkit-column-gap: 35px;
}

.nav-menu .link-item {
    color: #fff;
    position: relative;
    white-space: nowrap;
}

.nav-menu .link-item a {
    color: #fff;
}

.nav-menu .link-item::before {
    content: "";
    background-color: #fff;
    width: 0;
    inset: auto auto 0 0;
    height: 1px;
    position: absolute;
    border-radius: 2px;
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.nav-menu .link-item:hover::before {
    width: 100%;
}

.lang-dd {
    display: flex;
    position: relative;
    cursor: pointer;
}

.lang-dd img {
    cursor: pointer;
}

.hide {
    display: none;
}

.language {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.2);
    color: #000;
    padding: 10px 20px;
    margin-top: 50px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.language button {
    border: none;
    background: transparent;
    font-size: inherit;
    padding: 10px;
    cursor: pointer;
}

.language a {
    color: #000;
    font-size: inherit;
    cursor: pointer;
    padding: 10px;
}

.dropdown {
    display: flex;
    align-items: center;
    column-gap: 3px;
}

.dropdown .dropbtn {
    z-index: 2;
    position: relative;
}

.dropdown:hover .ddown {
    opacity: 1;
    /* visibility: visible; */
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}

.dropdown span {
    display: inline-flex;
    color: #fff;
}

.ddown {
    position: absolute;
    /*left: 25%;
  */
    z-index: 1;
    transform: scale(1, 0);
    transform-origin: top;
    transition: all .5s;
    top: 50px;
    opacity: 0;
    /*visibility: hidden;
  */
    -webkit-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
}

.dropdown-content {
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 24px 40px;
    margin-top: 10%;
    transform: translateZ(1000px);
    will-change: opacity, transform;
    transition: 0.4s ease;
    transition-delay: 0.4s;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    -webkit-transform: translateZ(1000px);
    -moz-transform: translateZ(1000px);
    -ms-transform: translateZ(1000px);
    -o-transform: translateZ(1000px);
    -webkit-transition: 0.4s ease;
    -moz-transition: 0.4s ease;
    -ms-transition: 0.4s ease;
    -o-transition: 0.4s ease;
}

.dropdown-content a {
    float: none;
    padding: 8px 0;
    text-decoration: none;
    display: block;
    text-align: left;
    color: #000 !important;
}

.dropdown-content a:hover {
    color: var(--purple) !important;
}

.on-mobo {
    display: none;
}

.navbar-right .hmb {
    display: none;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1.2em;
}

.active-page::before {
    content: "";
    background-color: #fff;
    width: 100%;
    inset: auto auto 0 0;
    height: 1px;
    position: absolute;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}


/* navbar scroll */

.navbar.active {
    background-color: #fff;
    box-shadow: 5px -1px 12px -5px grey;
}

.navbar.active .brand {
    color: #000;
}

.navbar.active .nav-menu .link-item a {
    color: #000;
}

/* .navbar.active .nav-menu .link-item a:hover {
    color: var(--purple);
} */

.navbar.active .navbar-right .lang-icon {
    filter: invert(0%) sepia(3%) saturate(100%) hue-rotate(142deg) brightness(0) contrast(99%);
    -webkit-filter: invert(0%) sepia(3%) saturate(100%) hue-rotate(142deg) brightness(0) contrast(99%);
}

.navbar.active .nav-menu .link-item::before {
    background-color: #000;
}

.navbar.active .dropdown-content .link-item::before {
    background-color: transparent;
}

.navbar.active .active-page::before {
    background-color: #000;
}

.navbar.active .dropdown span {
    filter: invert(0%) sepia(3%) saturate(100%) hue-rotate(142deg) brightness(0) contrast(99%);
    -webkit-filter: invert(0%) sepia(3%) saturate(100%) hue-rotate(142deg) brightness(0) contrast(99%);
}

/* .navbar.active .navbar-right .btn-primary:hover {
    background: var(--gradient);
    color: #fff;
}

.navbar.active .navbar-right .btn-primary::after {
    background: var(--gradient);
} */

.navbar.active .navbar-right .btn-secondary {
    color: var(--purple);
    border: 1px solid var(--purple);
}

.navbar.active .navbar-right .btn-secondary:hover {
    color: #fff;
    background: var(--purple);
}

.navbar.active .navbar-right .btn-secondary:hover::after {
    background: var(--purple);
}

@media screen and (max-width: 767px) {
    .brand {
        font-size: 1.7em;
    }
}

@media screen and (max-width: 990px) {
    .nav-menu {
        display: none;
    }
    .nav-menu#hidden-menu {
        position: absolute;
        inset: 0 auto auto 0;
        width: 100%;
        flex-direction: column;
        background: url('../../../public/images/bg-hero.svg') no-repeat center;
        background-size: cover;
        border-radius: 5px;
        display: flex;
        row-gap: 20px;
        z-index: 2;
        max-height: 100vh;
        padding: 10% 20px;
        overflow: auto;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }
    .navbar-right {
        column-gap: 5px;
    }
    .nav-menu .link-item {
        width: fit-content;
        font-weight: 500;
        font-size: 20px;
    }
    .navbar-right .hmb {
        display: flex;
        z-index: 9;
    }
    .on-mobo {
        display: inline-flex;
    }
    .hide-mobo {
        display: none;
    }
    .dropdown {
        display: unset;
    }
    .dropdown span {
        filter: unset !important;
        -webkit-filter: unset !important;
}
    .btn-nav-container {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 40px 0;
    }
    .lang-dd {
        display: none;
    }
    .lang-dd span {
        color: #fff;
    }
    .lang-dd.on-mobo {
        display: flex;
        justify-content: center;
    }
    .lang-dd.on-mobo .language {
        margin-top: unset;
        margin-left: 100px;
    }
    .dropbtn {
        display: inline-block;
        margin-right: 10px;
    }
    .ddown {
        position: relative;
        top: 0;
        /* opacity: 1;
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1); */
}
    .dropdown-content {
        padding: 5px;
        opacity: 1;
        visibility: visible;
        margin-top: 0;
        background-color: inherit;
        box-shadow: unset;
    }
    .dropdown-content a {
        margin: 10px 0;
        padding: 0;
        color: #fff !important;
    }
    .navbar.active .link-item a {
        color: #fff !important;
    }

    .navbar.active .active-page::before {
        background-color: #fff !important;
    }
    .navbar.active .hmb {
        color: #000;
    }
}

@media screen and (max-width: 1199px) {
    .nav-menu {
        column-gap: 30px;
        -moz-column-gap: 30px;
        -webkit-column-gap: 30px;
    }
}