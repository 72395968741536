.hero,
.hero-home,
.hero-2 {
    color: #fff;
    position: relative;
    /* min-height: 90vh; */
}

.hero-home::before, .hero::before, .hero-2::before, .hero.scnd::before {
    content: '';
    background-image: url("../../../public/images/bg-hero.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    inset: 0;
    position: absolute;
    z-index: -2;
}

.hero-home::after, .hero::after, .hero-2::after, .hero.scnd::after {
    content: '';
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.35), transparent);
    position: absolute;
    inset: 0;
    z-index: -1;
}

.hero {
    padding: 8% 0 20% 0;
}

.hero-2 {
    padding: 10% 0;
}

.hero-home {
    padding-top: 5%;
}

.hero-home img {
    position: relative;
    margin-bottom: -18%;
}

.hero.scnd {
    padding: 10% 0;
}

.hero-wrapper {
    display: flex;
    align-items: center;
    column-gap: 20px;
    position: relative;
}

.hero-content {
    width: 40%;
}

.hero-image {
    width: 60%;
}

.hero-image img {
    max-width: unset;
}

.hero-wrap {
    text-align: center;
}

.hero-wrap p {
    font-weight: 500;
}

@media screen and (max-width: 767px) {
    .hero {
        padding-top: 25%;
    }
    .hero-home {
        padding-top: 25% !important;
    }
    .hero-wrapper {
        flex-wrap: wrap;
        row-gap: 30px;
    }
}

@media screen and (max-width: 990px) {
    .hero-2 {
        padding-top: 20%;
    }
    .hero-wrapper {
        flex-wrap: wrap;
    }
    .hero-content, .hero-image {
        width: 100%;
    }
    .hero-image img {
        max-width: 100%;
    }
}

@media screen and (max-width: 1199px) {
    .hero-home {
        padding: 10% 0;
    }
}