footer {
    /* margin-top: -5%; */
    padding-bottom: 2%;
    background-color: #F7F5FF;
    position: relative;
}

footer .container {
    position: relative;
}

.footer-wrap {
    /* margin-top: -8%; */
    display: grid;
    grid-template-columns: 0.75fr 1fr;
    justify-content: space-between;
    gap: 80px;
    padding-top: 20%;
    padding-bottom: 20px;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.2);
}

.lang {
    display: flex;
    align-items: center;
    color: #565659;
    column-gap: 10px;
    margin-top: 40px;
    font-weight: 300;
}

.lang-dd span {
    margin-left: 10px;
}

.language.btm {
    bottom: 0;
    margin-left: 30px;
    box-shadow: 0px 0px 5px -3px hsl(0deg 0% 12% / 95%);
    flex-direction: column-reverse;
}

.footer-right {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
}

.footer-menu li:first-child {
    margin-bottom: 20px;
}

.footer-menu li {
    margin-bottom: 8px;
}

.footer-menu h2 {
    font-size: 1.25em;
}

.footer-menu a {
    color: #000;
    font-weight: 300;
}

.footer-left p, .footer-menu a {
    font-size: .9em;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.socmed {
    display: flex;
    gap: 15px;
}

.socmed-icon {
    font-size: 1.2em;
    cursor: pointer;
    position: relative;
    z-index: 8;
    transition: .3s ease;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -ms-transition: .3s ease;
    -o-transition: .3s ease;
    color: #565659;
}

.socmed-icon svg {
    cursor: pointer;
}

.socmed-icon:hover {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
}

.copyright {
    display: flex;
    column-gap: 20px;
    color: #565659;
    flex-wrap: wrap;
    white-space: nowrap;
}

.copyright a {
    color: #565659;
}

@media screen and (max-width: 767px) {
    .footer-wrap {
        display: flex;
        flex-direction: column;
    }
    .footer-right {
        grid-template-columns: repeat(2, 1fr);
        /* margin-top: 40px; */
    }
    .footer-bottom {
        flex-direction: column;
    }
    .socmed {
        margin-top: 40px;
    }
}

@media screen and (max-width: 767px) {
    /* footer {
        margin-top: 65% !important;
    } */
    .footer-wrap {
        padding-top: 70% !important;
    }
}

@media screen and (max-width: 990px) {
    .footer-right {
        grid-template-columns: repeat(2, 1fr);
    }
    .footer-wrap {
        padding-top: 30%;
    }
}
