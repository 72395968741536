#hc-list .item {
    background-color: #F7F5FF;
    text-align: center;
}

#hc-list a {
    display: inline-flex;
    justify-content: center;
}

.search {
    background-color: #fff;
    padding: 15px 24px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    display: flex;
    position: relative;
    margin: 40px auto 0 auto;
    column-gap: 10px;
    max-width: 60%;
    color: #000;
  }
  
  .search input {
    width: 100%;
    border: none;
  }
  
  .search input:focus {
    border: none;
    outline: none;
  }

  .result {
      /*max-width: 60%;
      */margin: 0 auto;
      background: #fff;
      padding: 15px 24px;
      display: flex;
      flex-direction: column;
      position: absolute;
      inset: 0 0 auto 0;
      margin-top: 5.5%;
      row-gap: 5px;
      border-radius: 5px;
      box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.2);
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
}

.dataItem {
    color: #000;
    padding: 5px 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.dataItem:hover {
    background: #ddd;
}

.decor.right-hc {
  right: 0;
  margin-top: 40%;
}

@media screen and (max-width: 767px) {
  .decor.right-hc {
    margin-top: 80%;
  }
  .right-faq {
    margin-top: 100%;
  }
}
