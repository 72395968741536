.contact-wrap {
    width: 60%;
    margin-inline: auto;
    border: 1px solid #ccc;
    border-radius: 30px;
    padding: 52px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    margin-bottom: 10%;
}

.success {
    display: flex;
    width: fit-content;
    text-align: center;
    margin: 20px auto auto auto;
    padding: 20px;
    background-color: aquamarine;
    position: relative;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.success span {
    position: absolute;
    inset: 0 0 auto auto;
    cursor: pointer;
    background-color: aliceblue;
    border-radius: 50px;
    height: fit-content;
    display: flex;
    z-index: 12;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

@media screen and (max-width: 767px) {
    .contact-wrap {
        padding: 50px 30px;
    }
}


@media screen and (max-width: 990px) {
    .contact-wrap {
        width: 100%;
    }
}