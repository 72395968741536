@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@700&family=Poppins:wght@300;400;500;600;700;800&display=swap");

/* Japanese font */

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');
* {
    margin: 0;
    padding: 0;
}

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    font-weight: normal;
    margin: 0;
    padding: 0;
}

ol,
ul {
    list-style: none;
}

img {
    height: auto;
    max-width: 100%;
}

section {
    position: relative;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

.hero-title {
    font-size: 3.4375em;
    line-height: 82px;
}

.hero-title-secondary {
    font-size: 2.5em;
    line-height: 60px;
}

.top-menu {
    color: var(--purple);
    font-weight: 800;
    font-size: 1em;
    letter-spacing: 0.04em;
}

.title {
    font-size: 2.5em;
    line-height: 60px;
    margin: 20px 0 10px 0;
}

.subtitle {
    color: hsla(0, 0%, 0%, 0.65);
}

.main {
    position: relative;
    margin-top: -20%;
}

.title-small {
    font-size: 1.125em;
    line-height: 21px;
    margin: 20px 0 10px 0;
}

.center {
    text-align: center;
}

.justify-center {
    justify-content: center;
}


/* css variable */

:root {
    --gradient: linear-gradient(135deg, #866cf9 0%, #9884f5 100%);
    --black-primary: #0a0231;
    --black-secondary: rgba(0, 0, 0, 0.65);
    --purple: #856bfa;
}

body {
    font-family: "Poppins", sans-serif;
    color: var(--black-primary);
    font-size: 1em;
    line-height: 30px;
    letter-spacing: 0.01em;
}

.btn,
.btn-hero {
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 600;
    color: #fff;
    border: none;
    font-size: 1em;
}

.btn {
    padding: 12px 27px;
    white-space: nowrap;
    position: relative;
}

.btn-hero {
    padding: 18px 32px;
}

.btn-primary {
    background: var(--gradient);
    transition: .3s ease-in;
    position: relative;
    -webkit-transition: .3s ease-in;
    -moz-transition: .3s ease-in;
    -ms-transition: .3s ease-in;
    -o-transition: .3s ease-in;
}

/* .btn-primary:hover {
    background: #fff;
    color: var(--purple);
} */

.btn-primary::after,
.btn-secondary::after,
.btn-primary.bg-white::after,
.btn-white::after {
    content: '';
    z-index: -1;
    border-radius: 5px;
    position: absolute;
    transition: .3s ease;
    inset: 0;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -ms-transition: .3s ease;
    -o-transition: .3s ease;
}

.btn-primary::after {
    /* background: #fff; */
    background: var(--gradient);
}

.btn-secondary::after {
    background: transparent;
}

.btn-primary.bg-white::after,
.btn-white::after {
    background: #fff;
}

.btn-primary:hover::after {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.btn-primary.bg-white {
    background: #fff;
    transition: .3s ease;
    color: var(--purple);
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -ms-transition: .3s ease;
    -o-transition: .3s ease;
}

.btn-primary.bg-white:hover::after {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.btn-secondary {
    background-color: transparent;
    border: 1px solid #fff;
    transition: .3s ease;
    position: relative;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -ms-transition: .3s ease;
    -o-transition: .3s ease;
}

.btn-secondary:hover {
    color: var(--purple);
}

.btn-secondary:hover::after {
    background-color: #fff;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.btn-transparent {
    display: flex;
    align-items: center;
    cursor: pointer;
    column-gap: 20px;
    color: var(--purple);
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    -o-transition: 0.3s ease;
}

.btn-transparent:hover {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
}

.btn-white {
    background-color: #fff;
    color: var(--purple);
    transition: .3s ease;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -ms-transition: .3s ease;
    -o-transition: .3s ease;
}

.btn-white:hover::after {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.white {
    color: #fff;
}

.section {
    padding: 100px 0;
}

.section-2 {
    padding: 100px 0;
}

.container {
    margin: 0 auto;
    padding: 0 20px;
}

.mb-5 {
    margin-bottom: 15%;
}

.mb-10 {
    margin-bottom: -8%;
}

.i-block {
    display: inline-block;
}

.w {
    width: 70%;
}

.m-auto {
    margin-inline: auto;
}

.mt-2 {
    margin-top: 20px !important;
}

.mt-4 {
    margin-top: 40px;
}

.decor {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
}

.decor.left {
    left: -50%;
}

.form-bg {
    background: url('../public/images/form-bg.svg') no-repeat center;
    background-size: cover;
}

.right {
    right: 0;
    bottom: 0;
}

.cookie-wrap {
    background-color: #fff !important;
    color: #000 !important;
    width: 90% !important;
    inset: auto 50px 30px 50px !important;
    box-shadow: 0px 20px 40px -3px rgba(0, 0, 0, 0.08);
    border-radius: 1000px;
    font-size: 1.1em;
    font-weight: 500;
    padding: 10px 50px;
    display: flex !important;
    align-items: center !important;
    -webkit-border-radius: 1000px;
    -moz-border-radius: 1000px;
    -ms-border-radius: 1000px;
    -o-border-radius: 1000px;
}

.cookie-content {
    font-size: 1em;
    line-height: 27px;
}


/* Tab */

.tab-switch {
    display: inline-flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.switch {
    padding: 10px 30px;
    border-radius: 100px;
    transition: .3s ease;
    font-weight: 600;
    cursor: pointer;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -ms-transition: .3s ease;
    -o-transition: .3s ease;
}

.switch.active {
    background-color: #fff;
    color: var(--purple);
    /* background-color: var(--purple); */
}

.hidden {
    /*display: none;
  */
    position: absolute;
    z-index: -1;
    opacity: 0;
    transform: translateX(50px);
    transition: .3s ease;
    -webkit-transform: translateX(50px);
    -moz-transform: translateX(50px);
    -ms-transform: translateX(50px);
    -o-transform: translateX(50px);
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -ms-transition: .3s ease;
    -o-transition: .3s ease;
}

@media screen and (max-width: 400px) {
    .cookie-content {
        line-height: 20px;
        flex: auto !important;
        margin: 0 !important;
    }
}

@media screen and (max-width: 767px) {
    .container {
        max-width: 100%;
    }
    .w,
    .left-content {
        width: 100%;
    }
    .p-20 {
        padding-top: 20% !important;
    }
    .mb-mobo {
        margin-bottom: 65% !important;
    }
}

@media screen and (max-width: 990px) {
    .container {
        max-width: 720px;
    }
    .hero-title {
        font-size: 2.5em;
        line-height: 60px;
    }
    .cookie-wrap {
        font-size: .5em;
        width: unset !important;
        inset: auto 0 10px 0 !important;
        padding: 10px 30px;
        flex-wrap: nowrap !important;
    }
    .mb-mobo {
        margin-bottom: 25%;
    }
    #pricing {
        padding-top: 15%;
    }
}

@media screen and (max-width: 1199px) {
    .container {
        max-width: 960px;
    }
}

@media screen and (max-width: 1390px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }
}